import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom/server"
import { css } from "@emotion/react"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { Localized } from "../../../../../packages/localization/Localized"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { AdyenComponent } from "../../../../../packages/adyen/web/AdyenComponent"
import { useGeneralPhoneOrderTermsAndConditions, useMe } from "../../client"
import { Heading } from "../../ui/components/typography/Heading"
import { Flex } from "../../ui/components/base/Flex"
import { useCheckoutContext } from "./CheckoutContext"
import { CheckoutStepSummaryBlock } from "../../ui/components/blocks/CheckoutStepSummaryBlock"
import { Assert, CountryCode, Markdown, Uuid } from "../../../../../reactor"
import { MustacheString } from "../../../../../packages/editing/Mustache"
import { Box } from "../../ui/components/base/Box"
import { Text } from "../../ui/components/typography/Text"
import {
    useCheckoutShippingState,
    useCheckoutTradeInForm,
    useCheckoutTradeInPriceRequest,
    useCheckoutTradeInResult,
} from "../Checkout"
import { Button, responsiveButtonCss } from "../../ui/components/buttons/Button"
import { colors } from "../../ui/constants/colors"
import { responsiveBorderRadius, responsiveCss } from "../../ui/helpers/css"
import { xlScaleFactor } from "../../ui/constants/sizes"
import { Icon } from "../../ui/components/visual/Icon"
import { TextInput } from "../../ui/components/controllers/TextInput"
import { LocaleKey } from "../../../../../packages/localization/Locale"
import { Divider } from "../../ui/components/other/Divider"
import { SoldOut } from "./SoldOut"
import { MarkdownView } from "../../ui/components/base/MarkdownView"
import { Page } from "../../../model/Page"
import { useNavigate } from "../../../../../packages/hooks/useNavigate"
import { useWebPageInfo } from "../../../../../studio/client"
import { monthsShort, useFormatAmount } from "../../ui/constants/text"
import { Body } from "../../ui/components/typography/Body"
import { ActionHandledReturnObject } from "@adyen/adyen-web/dist/types/components/types"

export type PaymentStepProps = {
    /**
     * Name of this step, used in checkout step navigation bar, and possibly other places the
     * step is referenced, like the summary page.
     *
     * @default '{"en": "Payment", "no": "Betaling"}'
     */
    name: Localized<string>

    /**
     * Payment step identifier to use in the URL.
     * @default '{"en": "payment", "no": "betaling"}'
     */
    slug: Localized<string>

    /**
     * @default '{"en": "Checkout", "no": "Betaling"}'
     */
    heading: Localized<string>

    payment: {
        /**
         * Text informing the user that entered payment details will be saved for future charges.
         * @default '{"no": "Vår betalingsløsning kommer til å lagre dine kortdetaljer for å kunne gjøre fremtidige trekk mot ditt abonnement automatisk. Ved å gjennomføre betalingen godtar du dette."}'
         */
        paymentDetailsWillBeStoredText: Localized<Markdown>

        /**
         * Text that will be presented if an error occurs during payment.
         * @default '{"no": "Det skjedde en feil ved betaling av ordren. Vennligst prøv igjen, eller ta kontakt med oss dersom problemet vedvarer."}'
         */
        paymentErrorMessage: Localized<Markdown>

        /**
         * @default '{"no": "Kortholders navn", "en": "Cardholder name"}'
         */
        cardholderNamePlaceholder: Localized<string>

        /**
         * @default '{"no": "Mangler leveringsmetode. Gå tilbake til forrige steg for å velge en leveringsmetode."}'
         */
        missingShippingMethod: Localized<Markdown>

        /**
         * @default '{"no": "En ukjent feil skjedde ved reservering av telefonen. Vennligst prøv igjen, ved å gå gjennom bestillingsflyten en gang til, eller ta kontakt med oss hvis problemet vedvarer."}'
         */
        orderReservationError: Localized<Markdown>
    }
    /**
     * Error messages to show in case the user has blocked, fails, or qualifies for manual processing.
     * If the relevant error message is not set, we'll fall back to the regular payment error message.
     */
    creditCheckMessages?: {
        /**
         * Message to show to users we were not able to credit check due to blocking.
         */
        blocked?: Localized<Markdown>
        /**
         * Message to show to users with a credit score below the defined minimum limits for manual
         * processing or auto pass.
         */
        tooLow?: Localized<Markdown>
        /**
         * Message to show to users with a credit score above the defined minimum for manual
         * processing but below the defined minimum for auto pass.
         */
        manual?: Localized<Markdown>
    }

    /**
     * Order summary.
     */
    summary: {
        /**
         * @default '{"en": "Summary", "no": "Oppsummering"}'
         */
        heading: Localized<string>

        device: {
            /**
             * @default '{"no": "Telefon", "en": "Phone"}'
             */
            stepName?: Localized<string>

            /**
             * @default '{"no": "Totalpris for telefonen etter {{rentalPeriod}} er {{totalPrice}}"}'
             */
            rentalPeriodTotalPrice: Localized<string>
        }

        tradeIn: {
            /**
             * @default '{"en": "We will deduct {{monthlyDiscount}} for the next {{discountPeriod}} months", "no": "Vi trekker fra {{monthlyDiscount}} på regningen din de neste {{discountPeriod}} månedene"}'
             */
            text: Localized<string>

            /**
             * @default '[{"id": "%UUID%", "text": {"no": "Du vil motta en bekreftelses-e-post med gratis fraktetikett."}}, {"id": "%UUID%", "text": {"no": "Vi kontrollerer mobilen og sier fra om ev. justeringer i verdi."}}, {"id": "%UUID%", "text": {"no": "Din månedlige kostnad reduseres allerede fra første betaling"}}]'
             */
            list?: {
                readonly id: Uuid
                /**
                 * @title
                 */
                text: Localized<string>
            }[]
        }

        shipping: {
            /**
             * @default '{"no": "Leveres {{deliveryDate}} mellom {{deliverySlotFrom}} og {{deliverySlotTo}}"}'
             */
            deliverySlotText: Localized<string>

            /**
             * Available variables are `{{deliveryOptionName}}` and `{{deliveryOptionDescription}}`
             * @default '{"no": "{{deliveryOptionDescription}}"}'
             */
            otherDeliveryOptionText?: Localized<string>
        }
    }

    /**
     * The page to go to for order confirmation.
     */
    orderConfirmationPage: Page["id"]
}
export function PaymentStep(props: PaymentStepProps) {
    const me = useMe()
    const context = useCheckoutContext()
    const navigate = useNavigate()
    const { useOrder } = context
    const localize = useLocalize()
    const formatAmount = useFormatAmount()
    const [checkoutShippingState] = useCheckoutShippingState()
    const [checkoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [checkoutTradeInForm] = useCheckoutTradeInForm()
    const [checkoutTradeInResult] = useCheckoutTradeInResult()
    const { data: tnc, loading: tncIsLoading } = useGeneralPhoneOrderTermsAndConditions()
    const { order, ensureOrder, isLoading, errorMessage } = useOrder()
    const [phoneIsNotAvailable, setPhoneIsNotAvaialable] = useState<boolean | undefined>(undefined)
    const [dropinIsReady, setDropinIsReady] = useState(false)
    const [multiplePaymentMethods, setMultiplePaymentMethods] = useState(false)
    const adyenDropinContainerRef = useRef<HTMLDivElement>(null)
    const [paymentError, setPaymentError] = useState(false)
    const [creditCheckError, setCreditCheckError] = useState<
        "blocked" | "tooLow" | "manual" | undefined
    >(undefined)
    const confirmationPageInfo = useWebPageInfo(props.orderConfirmationPage)

    const handleDropinReady = useCallback(() => {
        const numberOfPaymentMethods =
            adyenDropinContainerRef.current?.querySelectorAll(".adyen-checkout__payment-method")
                .length ?? 0
        setMultiplePaymentMethods(numberOfPaymentMethods > 1)
        setDropinIsReady(true)
    }, [])

    dayjs.extend(advancedFormat)

    const postAnalyticsCheckoutPaymentEvent = useCallback(
        async (event: string, data?: any) => {
            void context.postAnalyticsCheckoutEvent(["step", "payment", event], data)
        },
        [context]
    )

    const shippingSlotId =
        checkoutShippingState.selectedOption?.type === "Manual"
            ? checkoutShippingState.selectedOption.selectedSlot?.id
            : undefined
    const ensureCheckoutOrder = useCallback(async () => {
        void ensureOrder({
            phone: context.offer,
            shippingAddress: {
                street: context.shipping.form?.street.value ?? "",
                unit: context.shipping.form?.unit.value,
                city: context.shipping.form?.region.value ?? "",
                zip: context.shipping.form?.postalCode.value ?? "",
                country: CountryCode(context.shipping.form?.countryCode.value ?? ""),
            },
            shippingType: Assert(checkoutShippingState.selectedOption?.type),
            shippingSlot:
                checkoutShippingState.selectedOption?.type === "Manual"
                    ? shippingSlotId
                    : undefined,
            tradeIn: checkoutTradeInPriceRequest,
            termsAndConditions: Assert(tnc?.phoneOrder.id),
            insuranceTermsAndConditions: Assert(tnc?.insurance.id),
        })
            .then(() => {
                setPhoneIsNotAvaialable(false)
            })
            .catch(() => {
                setPhoneIsNotAvaialable(true)
            })
    }, [
        shippingSlotId,
        checkoutShippingState.selectedOption?.type,
        checkoutTradeInPriceRequest,
        context.offer,
        context.shipping.form?.countryCode.value,
        context.shipping.form?.postalCode.value,
        context.shipping.form?.region.value,
        context.shipping.form?.street.value,
        context.shipping.form?.unit.value,
        ensureOrder,
        tnc?.insurance.id,
        tnc?.phoneOrder.id,
    ])

    const handlePaymentError = useCallback(
        (err: any) => {
            // Check if payment error is due to failed credit check.
            if (err?.type === "CreditCheckBlocked") {
                setCreditCheckError("blocked")
                void postAnalyticsCheckoutPaymentEvent("credit-check-blocked")
            } else if (err?.type === "CreditCheckManualProcessing") {
                setCreditCheckError("manual")
            } else if (err?.type === "CreditScoreTooLow") {
                setCreditCheckError("tooLow")
            } else {
                void postAnalyticsCheckoutPaymentEvent("error")
            }

            setPaymentError(true)
            setDropinIsReady(false)
            void ensureCheckoutOrder()
        },
        [ensureCheckoutOrder, postAnalyticsCheckoutPaymentEvent]
    )

    useEffect(() => {
        if (isLoading || tncIsLoading) return
        if (phoneIsNotAvailable !== undefined) return

        void ensureCheckoutOrder()
    }, [isLoading, ensureCheckoutOrder, phoneIsNotAvailable, tncIsLoading])

    const selectedInsurance = useMemo(
        () =>
            context.insuranceOptions.data?.find(
                (insurance) => insurance.id === context.offer.insurance
            ),
        [context.insuranceOptions.data, context.offer.insurance]
    )

    const creditCheckErrorMessage = useMemo(
        () =>
            !!creditCheckError && props.creditCheckMessages?.[creditCheckError]
                ? localize(props.creditCheckMessages[creditCheckError])
                : undefined,
        [creditCheckError, localize, props.creditCheckMessages]
    )

    if (isLoading || tncIsLoading) return <></>

    if (!order && checkoutShippingState.selectedOption?.type === "Manual" && !shippingSlotId) {
        return (
            <Body size="md">
                <MarkdownView value={localize(props.payment.missingShippingMethod)} />
            </Body>
        )
    }

    if (errorMessage) {
        return (
            <Body size="md">
                <MarkdownView value={localize(props.payment.orderReservationError)} />
            </Body>
        )
    }

    if (!order) return <></>

    if (phoneIsNotAvailable) {
        return <SoldOut {...context.props.SoldOut} />
    }

    return (
        <Flex direction="column" gap={32}>
            <Heading level={2}>{localize(props.heading)}</Heading>
            <div style={{ position: "relative" }}>
                {!dropinIsReady && !paymentError && (
                    <Flex gap={16} direction="column" alignItems="stretch">
                        <TextInput disabled />
                        <Flex gap={16}>
                            <TextInput fullwidth disabled />
                            <TextInput fullwidth disabled />
                        </Flex>
                        <TextInput disabled />
                        <Button size="md" variant="primary" disabled></Button>
                    </Flex>
                )}
                {!!creditCheckErrorMessage && <MarkdownView value={creditCheckErrorMessage} />}
                {paymentError && !creditCheckErrorMessage && (
                    <div>
                        <MarkdownView value={localize(props.payment.paymentErrorMessage)} />
                        <Button
                            variant="dark"
                            margin={{ top: 16 }}
                            size="sm"
                            onClick={() => setPaymentError(false)}
                        >
                            {localize({ no: "Prøv igjen", en: "Try again" })}
                        </Button>
                    </div>
                )}
                {phoneIsNotAvailable === false && !paymentError && (
                    <div
                        ref={adyenDropinContainerRef}
                        css={css(
                            adyenPaymentFormCss,
                            !multiplePaymentMethods
                                ? css({
                                      ".adyen-checkout__payment-method__header": {
                                          display: "none",
                                      },
                                  })
                                : {}
                        )}
                        style={
                            !dropinIsReady
                                ? {
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      opacity: 0,
                                  }
                                : {}
                        }
                    >
                        <AdyenComponent
                            onSuccess={() => {
                                void postAnalyticsCheckoutPaymentEvent("success")

                                if (confirmationPageInfo.data?.slug) {
                                    navigate(
                                        `/${localize(confirmationPageInfo.data.slug)}?o=${order.id}`
                                    )
                                } else {
                                    alert("Missing confirmation page configuration.")
                                }
                            }}
                            onConfigSuccess={() => {
                                void postAnalyticsCheckoutPaymentEvent("card-form-ready")
                            }}
                            onSubmitCallback={(state: any) => {
                                void postAnalyticsCheckoutPaymentEvent("submit")
                            }}
                            onError={handlePaymentError}
                            adyenOrder="phone-subscription"
                            adyenOrderId={
                                // The Adyen Order ID is encoded as
                                // `${orderId}_${periodIndex}`. We are paying for the
                                // first period (0).
                                `${order.id.valueOf()}_0`
                            }
                            DropinElementProps={{
                                onReady: handleDropinReady,
                                instantPaymentTypes: ["applepay"],
                            }}
                            onActionHandled={(data: ActionHandledReturnObject) => {
                                void postAnalyticsCheckoutPaymentEvent("action-handled", {
                                    componentType: data.componentType,
                                    actionDescription: data.actionDescription,
                                })
                            }}
                            translations={{
                                "no-NO": {
                                    "creditCard.holderName.placeholder": localize(
                                        props.payment.cardholderNamePlaceholder,
                                        LocaleKey.no
                                    ),
                                },
                                "en-US": {
                                    "creditCard.holderName.placeholder": localize(
                                        props.payment.cardholderNamePlaceholder,
                                        LocaleKey.en
                                    ),
                                },
                            }}
                            filterPaymentMethods={
                                me.data?.disabledPaymentMethods
                                    ? (pm) => !me.data?.disabledPaymentMethods?.includes(pm.type)
                                    : undefined
                            }
                            styles={{
                                base: {
                                    color: colors.gray500,
                                    fontWeight: "300",
                                    fontSize: "16px",
                                },
                                placeholder: {
                                    color: colors.gray300,
                                    fontWeight: "300",
                                    fontSize: "16px",
                                },
                            }}
                        />
                    </div>
                )}
            </div>
            {!creditCheckErrorMessage && (
                <Box backgroundColor="gray100" borderRadius="md" padding="md">
                    <Text variant="body" size="md" color="gray400">
                        <MarkdownView
                            value={localize(props.payment.paymentDetailsWillBeStoredText)}
                        />
                    </Text>
                </Box>
            )}
            <Divider horizontal spacing={0} />
            <Flex direction="column" gap={24}>
                <Heading level={3}>{localize(props.summary.heading)}</Heading>
                <CheckoutStepSummaryBlock
                    icon="device"
                    step={localize(props.summary.device.stepName ?? {})}
                    title={localize(context.offer.name)}
                    price={
                        context.price?.monthlyCost.phone
                            ? `${formatAmount(context.price.monthlyCost.phone)}/${localize(monthsShort)}`
                            : undefined
                    }
                    subtitle={MustacheString(
                        localize(props.summary.device.rentalPeriodTotalPrice),
                        {
                            rentalPeriod: `${context.offer.rentalPeriod.valueOf()} ${localize(monthsShort)}`,
                            totalPrice: context.price
                                ? `${formatAmount({ minorUnits: context.price.monthlyCost.phone.minorUnits * context.offer.rentalPeriod.valueOf(), majorUnits: context.price.monthlyCost.phone.majorUnits * context.offer.rentalPeriod.valueOf(), currency: context.price.monthlyCost.phone.currency })}`
                                : "",
                        }
                    )}
                />
                {checkoutTradeInForm &&
                checkoutTradeInPriceRequest &&
                typeof checkoutTradeInResult?.value !== "undefined" &&
                context.price?.monthlyCost.tradeInDiscount &&
                checkoutTradeInResult.deviceName ? (
                    <CheckoutStepSummaryBlock
                        icon="handCoins"
                        step={localize(context.props.TradeIn.name)}
                        title={localize(checkoutTradeInResult.deviceName)}
                        subtitle={MustacheString(localize(props.summary.tradeIn.text), {
                            monthlyDiscount: formatAmount(checkoutTradeInResult.monthlyDiscount),
                            discountPeriod: `${checkoutTradeInResult.months.valueOf()}`,
                        })}
                        price={formatAmount(checkoutTradeInResult.value)}
                        list={props.summary.tradeIn.list?.map((li) => ({
                            id: li.id.valueOf(),
                            text: localize(li.text),
                        }))}
                    />
                ) : null}
                {selectedInsurance && context.price?.monthlyCost.insurance ? (
                    <CheckoutStepSummaryBlock
                        icon="shield"
                        step={localize(context.props.Insurance.name)}
                        title={localize(selectedInsurance.displayName)}
                        price={`${formatAmount(context.price.monthlyCost.insurance)}/${localize(monthsShort)}`}
                        list={selectedInsurance.sellingPoints.map((isp) => ({
                            id: isp.id.valueOf(),
                            text: localize(isp.text),
                        }))}
                    />
                ) : null}
                {checkoutShippingState.selectedOption ? (
                    <CheckoutStepSummaryBlock
                        icon="truck"
                        step={localize(context.props.Shipping.name)}
                        title={localize(checkoutShippingState.selectedOption.name)}
                        subtitle={
                            checkoutShippingState.selectedOption.type === "Manual" &&
                            checkoutShippingState.selectedOption.selectedSlot
                                ? MustacheString(
                                      localize(props.summary.shipping.deliverySlotText),
                                      {
                                          deliveryDate: dayjs(
                                              checkoutShippingState.selectedOption.selectedSlot.date
                                          ).format("dddd Do MMMM"),
                                          deliverySlotFrom:
                                              checkoutShippingState.selectedOption.selectedSlot
                                                  .from,
                                          deliverySlotTo:
                                              checkoutShippingState.selectedOption.selectedSlot.to,
                                      }
                                  )
                                : props.summary.shipping.otherDeliveryOptionText
                                  ? MustacheString(
                                        localize(props.summary.shipping.otherDeliveryOptionText),
                                        {
                                            deliveryOptionName: localize(
                                                checkoutShippingState.selectedOption.name
                                            ),
                                            deliveryOptionDescription: localize(
                                                checkoutShippingState.selectedOption.description
                                            ),
                                        }
                                    )
                                  : localize(checkoutShippingState.selectedOption.description)
                        }
                        price={
                            context.price && context.price.shippingCost.majorUnits.valueOf() > 0
                                ? formatAmount(context.price.shippingCost)
                                : localize({ no: "Gratis", en: "Free" })
                        }
                        text={[
                            checkoutShippingState.form?.street.value,
                            `${checkoutShippingState.form?.postalCode.value} ${checkoutShippingState.form?.region.value}`,
                        ]
                            .filter((t) => !!t)
                            .join(", ")}
                    />
                ) : null}
            </Flex>
        </Flex>
    )
}

export const adyenPaymentFormCss = css(
    {
        ".adyen-checkout-form-instruction": {
            display: "none",
            fontSize: 16,
            color: colors.gray300,
        },
        ".adyen-checkout-input__inline-validation": {
            height: 24,
            width: 24,
        },
        ".adyen-checkout-input__inline-validation img": {
            display: "none",
        },
        ".adyen-checkout-input__inline-validation.adyen-checkout-input__inline-validation--valid:after":
            {
                display: "block",
                content: `url("data:image/svg+xml,${encodeURIComponent(ReactDOM.renderToString(<Icon icon="check" color="forest" />))}")`,
                width: 24,
                height: 24,
            },
        ".adyen-checkout__payment-method": {
            backgroundColor: "#fff",
            padding: 0,
            border: 0,
        },
        "li.adyen-checkout__payment-method": {
            marginBottom: 16,
        },
        "li.adyen-checkout__payment-method:last-of-type": {
            marginBottom: 0,
        },
        ".adyen-checkout__payment-method__header": {
            padding: 0,
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-between",
        },
        "li.adyen-checkout__payment-method:last-of-type:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__header":
            {
                marginBottom: 0,
            },
        ".adyen-checkout__payment-method__details": {
            padding: 0,
        },
        "button.adyen-checkout__payment-method__header__title": {
            display: "flex",
            padding: 0,
            alignItems: "stretch",
        },
        ".adyen-checkout__payment-method__name_wrapper": {
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
        },
        ".adyen-checkout__payment-method__radio": { opacity: 0 },
        ".adyen-checkout__payment-method__name": {
            lineHeight: "120%",
        },
        ".adyen-checkout__button.adyen-checkout__button--pay": css(
            { marginTop: 16 },
            responsiveButtonCss("primary", "md"),
            {
                borderRadius: "100px",
            }
        ),
        ".adyen-checkout__button__icon": {
            display: "none",
        },
        ".adyen-checkout__button__text": {
            lineHeight: "120%",
        },
        [[
            ".adyen-checkout__button:hover",
            ".adyen-checkout__button:focus",
            ".adyen-checkout__button:focus:hover",
        ].join(",")]: {
            boxShadow: "none",
        },
        ".adyen-checkout__payment-method__image__wrapper--outline:after": { display: "none" },
        ".adyen-checkout__input": {
            borderColor: colors.gray200,
            borderWidth: 2,
            height: 48 * xlScaleFactor,
            padding: `6px ${20 * xlScaleFactor}px`,
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            fontWeight: "300",
            WebkitFontSmoothing: "auto",
            MozOsxFontSmoothing: "auto",
            fontSmooth: "auto",
            fontSize: "16px",
        },

        "input.adyen-checkout__input::placeholder": {
            fontWeight: "300",
            color: colors.gray300,
        },
        ".adyen-checkout__input:hover": {
            borderColor: colors.gray200,
            borderWidth: 2,
            boxShadow: "none",
        },
        ".adyen-checkout__input:focus, .adyen-checkout__input--focus": {
            borderColor: colors.brand,
            borderStyle: "solid",
            borderWidth: 2,
            boxShadow: "none",
        },
        [[
            ".adyen-checkout__input:focus:hover",
            ".adyen-checkout__input:active",
            ".adyen-checkout__input:active:hover",
            ".adyen-checkout__input--focus:hover",
        ].join(",")]: {
            border: `2px solid ${colors.brand}`,
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: colors.brand,
            boxShadow: "none",
        },
        ".adyen-checkout__applepay__button": {
            borderRadius: "100px",
        },
        ".adyen-checkout__label, .adyen-checkout__card__brands": {
            display: "none",
        },
        ".adyen-checkout__label__text": {
            fontSize: "16px",
            marginBottom: 8,
        },
        ".adyen-checkout__label--focused .adyen-checkout__label__text": {
            color: colors.gray500,
        },
        ".adyen-checkout__card__cardNumber__brandIcon": {
            right: 16,
        },
        "img.adyen-checkout__field__exp-date_hint": {
            marginRight: 0,
        },
        ".adyen-checkout__field__exp-date_hint_wrapper": {
            marginRight: 0,
            right: 16,
        },
        ".adyen-checkout__card__cvc__hint__wrapper": {
            marginRight: 0,
            right: 16,
        },
        [[".adyen-checkout__label__text--error", ".adyen-checkout__error-text"].join(",")]: {
            color: colors.error,
        },
        ".adyen-checkout__error-text": {
            margin: 0,
        },
        ".adyen-checkout__field--error .adyen-checkout__error-text": {
            marginTop: 16,
            marginBottom: 16,
        },
        ".adyen-checkout__field--error .adyen-checkout__card__brands": {
            marginTop: 0,
        },
        ".adyen-checkout__field--error .adyen-checkout__error-text:before": {
            display: "block",
            content: `url("data:image/svg+xml,${encodeURIComponent(ReactDOM.renderToString(<Icon icon="warning" color="error" />))}")`,
            width: 24,
            height: 24,
            marginRight: 8,
        },
        [[
            ".adyen-checkout__input--error",
            ".adyen-checkout__input--error:hover",
            ".adyen-checkout__input--error:active",
            ".adyen-checkout__input--error:active:hover",
            ".adyen-checkout__input--error:focus:hover",
            ".adyen-checkout__input--error--focus:hover",
            ".adyen-checkout__field--error input",
        ].join(",")]: {
            borderColor: colors.error,
        },
        [[
            ".adyen-checkout__input--valid",
            ".adyen-checkout__input--valid:hover",
            ".adyen-checkout__input--valid:active",
            ".adyen-checkout__input--valid:active:hover",
            ".adyen-checkout__input--valid:focus:hover",
            ".adyen-checkout__input--valid--focus:hover",
        ].join(",")]: {
            borderColor: colors.forest,
        },
        ".adyen-checkout__payment-method__details__content": {
            margin: 0,
        },
        ".error-description": {
            display: "none",
        },
    },
    responsiveCss("min", "md", {
        ".adyen-checkout__input": {
            height: 64 * xlScaleFactor,
        },
    }),
    css`
        .adyen-checkout__input {
            ${responsiveBorderRadius("sm")};
        }
    `
)
