import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { NavigateOptions, useSearchParams } from "react-router-dom"
import { Global } from "@emotion/react"
import { useSessionState } from "../../../../reactor/Web"
import { Markdown, Uuid } from "../../../../reactor"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Section } from "../../../../packages/editing/Section"
import { TradeInStepProps } from "./checkout/TradeInStep"
import { InsuranceStepProps } from "./checkout/InsuranceStep"
import { LoginStepProps } from "./checkout/LoginStep"
import { CheckoutShippingFormState, ShippingStepProps } from "./checkout/ShippingStep"
import { PaymentStepProps } from "./checkout/PaymentStep"
import {
    CheckoutContext,
    CheckoutState,
    CheckoutStep,
    checkoutSteps,
} from "./checkout/CheckoutContext"
import { checkoutNavigation } from "./checkout/CheckoutContext"
import {
    postExpirePhoneReservationOrder,
    getMe,
    GetPhoneOfferOptionsDto,
    getPhoneOrder,
    GetPhoneOrderDto,
    GetShippingOptionsDto,
    GetShippingOptionsOptions0ManualShippingOptionSlotsDto,
    GetTradeInFormDto,
    ManualShippingOption,
    PhoneOffer,
    PhoneOrderPrice,
    PhoneOrderRequest,
    PostalShippingShippingOption,
    postPhoneOrder,
    PostTradeInFormsValueDto,
    putMarketingConsent,
    TradeInPriceRequest,
    useInsuranceOptions,
    useMe,
    usePhoneOrderPrice,
    useTradeInForm,
} from "../client"
import { StepsNavigation } from "../ui/components/navigation/StepsNavigation"
import {
    DeviceCheckoutCard,
    DeviceCheckoutCardInfoItem,
} from "../ui/components/cards/DeviceCheckoutCard"
import { Flex } from "../ui/components/base/Flex"
import { css, responsiveCss, scaleValue } from "../ui/helpers/css"
import { colors } from "../ui/constants/colors"
import { LogoR } from "../ui/components/visual/LogoR"
import { TrustpilotStar } from "../ui/components/visual/TrustpilotStars"
import { Text } from "../ui/components/typography/Text"
import { ShippingOption } from "../../api/ShippingAPI"
import { Page } from "../../model/Page"
import { SoldOut } from "./checkout/SoldOut"
import { WebPageContext } from "../../../../packages/web/components/WebPage"
import { monthsShort, useFormatAmount, useLocalizeCurrency } from "../ui/constants/text"
import { OrderLimitReached } from "./checkout/OrderLimitReached"
import { ErrorPage } from "../pages/ErrorPage"
import { postAnalyticsEvent } from "../../../../studio/client"

function useCurrentPhoneOffer() {
    return useSessionState<PhoneOffer | undefined>("offer", undefined)
}

export function usePhoneOfferOptions() {
    return useSessionState<GetPhoneOfferOptionsDto | undefined>(
        "currentPhoneOfferOptions",
        undefined
    )
}

/**
 * Hooks for trade-in states.
 */
export function useCheckoutTradeInForm() {
    return useSessionState<GetTradeInFormDto | undefined>("checkoutTradeInForm", undefined)
}
export function useCheckoutTradeInPriceRequest() {
    return useSessionState<TradeInPriceRequest | undefined>(
        "checkoutTradeInPriceRequest",
        undefined
    )
}
export function useCheckoutTradeInResult() {
    return useSessionState<PostTradeInFormsValueDto | undefined>("checkoutTradeInResult", undefined)
}

function useCurrentShippingOption() {
    return useSessionState<
        { type: ShippingOption["type"]; id: Uuid<"ManualShippingSlot"> } | undefined
    >("shippingOption", undefined)
}

export type CheckoutShippingState = Partial<GetShippingOptionsDto> & {
    form?: CheckoutShippingFormState & {}
    selectedOption?:
        | (ManualShippingOption & {
              selectedSlot?: GetShippingOptionsOptions0ManualShippingOptionSlotsDto
          })
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        | PostalShippingShippingOption
}

/**
 * Single hook to hold shipping state.
 */
export function useCheckoutShippingState() {
    return useSessionState<CheckoutShippingState>("checkoutShippingState", {
        options: [],
        selectedOption: undefined,
        form: undefined,
    })
}

/**
 * Stores the parts of the checkout state that does not fit into the other hooks.
 */
export function useCheckoutState() {
    return useSessionState<CheckoutState>("checkoutState", {
        id: Uuid<"CheckoutState">(),
        currentStep: "TradeIn",
        availableSteps: [],
        marketingAccepted: false,
        termsAccepted: false,
    })
}

/**
 * Used to store whether or not user need to log in during checkout flow. Is set based on
 * login state when the checkout flow is entered.
 */
function useCheckoutSkipAuthStep() {
    return useSessionState<boolean | undefined>("checkoutSkipAuthStep", undefined)
}

function useCheckoutCalculatedOrderPrice() {
    return useSessionState<PhoneOrderPrice | undefined>("checkoutCalculatedOrderPrice", undefined)
}
export type CheckoutCalculatedOrderPrice = typeof useCheckoutCalculatedOrderPrice

/**
 * Gets or sets the current phone offer. Setting resets the checkout flow and clears
 * such things as trade-in and shipping.
 */
export function useResetPhoneOffer(): [PhoneOffer | undefined, (phoneOffer: PhoneOffer) => void] {
    const [offer, setOffer] = useCurrentPhoneOffer()
    const [, setShippingOption] = useCurrentShippingOption()
    const [, setCheckoutState] = useCheckoutState()
    const [, setCurrentTradeInStep] = useSessionState<number | undefined>(
        "tradein-modal-step",
        undefined
    )
    const id = Uuid<"CheckoutState">()
    const [, setTradeInResponses] = useSessionState<(Uuid<"TradeInResponse"> | undefined)[]>(
        "tradein-responses",
        []
    )
    const [, setTradeInResult] = useSessionState<PostTradeInFormsValueDto | undefined>(
        "tradein-result",
        undefined
    )
    const [, setTradeInModalOpen] = useSessionState("tradein-modal-open", false)
    const [, setSkipAuthStep] = useCheckoutSkipAuthStep()
    const [, setShippingState] = useCheckoutShippingState()

    const [, setCheckoutTradeInForm] = useCheckoutTradeInForm()
    const [, setCheckoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [, setCheckoutTradeInResult] = useCheckoutTradeInResult()
    const [, , resetConsents] = useCheckoutConsents()

    return [
        offer,
        (phoneOffer: PhoneOffer) => {
            setCheckoutState({
                id,
                currentStep: "TradeIn",
                availableSteps: ["TradeIn"],
                marketingAccepted: false,
                termsAccepted: false,
            })
            setOffer(phoneOffer)
            setShippingState({})
            setCurrentTradeInStep(0)
            setTradeInResponses([])
            setTradeInResult(undefined)
            setTradeInModalOpen(false)
            setShippingOption(undefined)
            setSkipAuthStep(undefined)
            setCheckoutTradeInForm(undefined)
            setCheckoutTradeInPriceRequest(undefined)
            setCheckoutTradeInResult(undefined)
            resetConsents()
        },
    ]
}

export type CheckoutDeviceCardProps = {
    /**
     * @default '{"no": "Pris per måned"}'
     */
    monthlyTotalPriceLabel: Localized<string>

    /**
     * @default '{"no": "Totalpris etter {{rentalPeriod}} er {{totalPrice}}"}'
     */
    rentalPeriodTotalPriceText: Localized<string>

    tradeInInfoItem: {
        /**
         * @default '{"en": "Trade-in cashback: {{cashback}}"}'
         */
        title: Localized<string>

        /**
         * @default '{"en": "We will deduct {{monthlyDiscount}} for the next {{discountPeriod}} months"}'
         */
        text: Localized<string>
    }
    insuranceInfoItem: {
        /**
         * @default '{"en": "Safety plan is added", "no": "Trygghetspakke er lagt til"}'
         */
        title: Localized<string>

        /**
         * @default '{"no": "Du tegner {{insuranceName}} til {{amount}}"}'
         */
        text: Localized<string>
    }
    shippingInfoItem: {
        /**
         * Available variables are `{{shippingName}}` and `{{shippingDescription}}`.
         * @default '{"no": "{{shippingName}}"}'
         */
        title: Localized<string>

        /**
         * Available variables are `{{shippingName}}`, `{{shippingDescription}}`, and `{{shippingPrice}}`.
         * @default '{"no": "{{shippingPrice}} betales nå."}'
         */
        text: Localized<string>

        /**
         * If a separate text for free shipping is required it can be set here.
         * @default '{"no": "Gratis frakt!", "en": "Free shipping!"}'
         */
        freeShippingText?: Localized<string>
    }
}

export type OrderLimitReachedProps = {
    /**
     * @default '{"no": "Maksgrense nådd"}'
     */
    heading: Localized<string>

    /**
     * @default '{"no": "Du har nådd maks antall bestillinger hos oss. Ta kontakt med oss dersom du mener dette er feil og ønsker å bestille mer."}'
     */
    text: Localized<Markdown>

    /**
     * @default '{"no": "Gå til forsiden"}'
     */
    primaryAction: {
        buttonText: Localized<string>

        /**
         * The page the primary action links to.
         */
        page: Page["id"]
    }
}

export type SoldOutProps = {
    /**
     * @default '{"no": "Oi, utleid!"}'
     */
    heading: Localized<string>

    /**
     * @default '{"no": "Telefonen du hadde valgt er ikke lenger tilgjengelig."}'
     */
    text: Localized<string>

    primaryAction: {
        /**
         * @default '{"no": "Finn en annen telefon"}'
         */
        buttonText: Localized<string>

        /**
         * The page to go to when clicking the primary button.
         */
        page: Page["id"]
    }
}

type CheckoutConsents = { marketing: { consentGiven: boolean; preConsented: boolean | undefined } }
export function useCheckoutConsents(): [
    CheckoutConsents,
    (val: CheckoutConsents) => void,
    () => void,
] {
    const [consents, setConsents] = useSessionState<CheckoutConsents>("checkout-consents", {
        marketing: { consentGiven: false, preConsented: undefined },
    })

    const getFromUser = useCallback(() => {
        if (consents.marketing.preConsented === undefined) {
            void getMe().then((result) => {
                setConsents({
                    marketing: {
                        consentGiven: !!result.marketingConsent,
                        preConsented: !!result.marketingConsent,
                    },
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getFromUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [
        consents,
        (val: CheckoutConsents) => {
            setConsents(val)
            void putMarketingConsent({ consent: val.marketing.consentGiven })
        },
        () => {
            setConsents({ marketing: { consentGiven: false, preConsented: undefined } })
            getFromUser()
        },
    ]
}

/**
 * Hook that holds the order in the checkout flow, and returns the order along with loading/error
 * statuses, and a function to ensure that an order exits, or is re-created for the provided phone
 * based on a `PhoneOrderRequest` object.
 */
export function useCheckoutOrder(): {
    order: GetPhoneOrderDto | undefined
    ensureOrder: (orderRequest: PhoneOrderRequest) => Promise<void>
    deleteOrder: () => Promise<void>
    isLoading: boolean
    errorMessage: string | undefined
} {
    const [order, setOrder] = useSessionState<GetPhoneOrderDto | undefined>(
        "CheckoutOrder",
        undefined
    )

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    const ensureOrder = useCallback(
        async (orderRequest: PhoneOrderRequest) => {
            if (isLoading) return
            setIsLoading(true)

            // If the phone is unchanged, check if current order exists. If the order doesn't
            // exist anymore, or we want an order for a different phone, we'll try to create
            // a new order below.
            if (order && order.phone.id === orderRequest.phone.id) {
                try {
                    const o = await getPhoneOrder(order.id)
                    setOrder(o)
                    setIsLoading(false)

                    return
                } catch (err) {
                    // Order does not exist any more and is expired, will try to create new one
                    // below, so no further error handling is needed.
                }
            }

            // Create new order, based on the config.
            try {
                const postOrderResult = await postPhoneOrder(orderRequest)
                const getOrderResult = await getPhoneOrder(postOrderResult.id)
                if (getOrderResult.id !== order?.id) {
                    setOrder(getOrderResult)
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err)
                setErrorMessage(JSON.stringify(err))
                throw err
            } finally {
                setIsLoading(false)
            }
        },
        [isLoading, order, setOrder]
    )

    const deleteOrder = useCallback(async () => {
        if (order?.id) {
            try {
                await postExpirePhoneReservationOrder(order.id)
            } catch (err) {
                // If this fails it is most likely because of an order id that should not have been
                // set anyway. It was probably deleted server side because of reservation
                // expiration set in order settings. So no more handling than just unsetting the
                // order, which we'll also do if the request succeeds.
            } finally {
                setOrder(undefined)
            }
        }
    }, [order?.id, setOrder])

    return { order, ensureOrder, deleteOrder, isLoading, errorMessage }
}
export type CheckoutOrderState = typeof useCheckoutOrder

function Checkout(section: {
    TradeIn: TradeInStepProps
    Insurance: InsuranceStepProps
    Login: LoginStepProps
    Shipping: ShippingStepProps
    Payment: PaymentStepProps
    SoldOut: SoldOutProps
    CheckoutDeviceCard: CheckoutDeviceCardProps
    /**
     * Message that will be shown to the user when they click the primary action button if they have
     * reached their order limit.
     */
    OrderLimitReached: OrderLimitReachedProps
    Trustpilot: {
        /**
         * The Trustpilot rating.
         */
        rating?: number

        /**
         * Hide the Trustpilot element.
         */
        hide: boolean
    }
}) {
    const localize = useLocalize()
    const [searchParams, setSearchParams] = useSearchParams()
    const [phoneOfferOptions] = usePhoneOfferOptions()
    const tradeInForm = useTradeInForm("Phones")
    const webPageContext = useContext(WebPageContext)
    const formatAmount = useFormatAmount()
    const localizeCurrency = useLocalizeCurrency()
    const [checkoutState, setCheckoutState] = useCheckoutState()
    const [offer, setOffer] = useCurrentPhoneOffer()
    const [checkoutTradeInForm, setCheckoutTradeInForm] = useCheckoutTradeInForm()
    const [checkoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [checkoutTradeInResult] = useCheckoutTradeInResult()
    const [shipping, setShipping] = useCheckoutShippingState()
    const [checkoutShippingState] = useCheckoutShippingState()
    const [skipAuthStep, setSkipAuthStep] = useCheckoutSkipAuthStep()
    const currentStep = useMemo(() => checkoutState.currentStep, [checkoutState.currentStep])
    const setCurrentStep = useCallback(
        (s: CheckoutStep) => {
            setCheckoutState({ ...checkoutState, currentStep: s })
        },
        [checkoutState, setCheckoutState]
    )
    const me = useMe()

    // Need a way to determine if the page is rendered client side and checkout state should be
    // available, so that an error can be presented if checkout state is missing.
    const [offerShouldBeLoaded, setOfferShouldBeLoaded] = useState(false)
    useEffect(() => {
        setOfferShouldBeLoaded(true)
    }, [])

    const { order, ensureOrder, deleteOrder, isLoading, errorMessage } = useCheckoutOrder()
    // Don't request updated price if we have an order, as it will result in not found error.
    const price = usePhoneOrderPrice(
        offer && !order?.id
            ? {
                  phone: offer,
                  shippingType: shipping.selectedOption?.type,
                  shippingSlot:
                      shipping.selectedOption?.type === "Manual"
                          ? shipping.selectedOption.selectedSlot?.id
                          : undefined,
                  tradeIn: checkoutTradeInPriceRequest,
              }
            : null
    )

    const [calculatedOrderPrice, setCalculatedOrderPrice] = useCheckoutCalculatedOrderPrice()

    useEffect(() => {
        if (price.data && JSON.stringify(price.data) !== JSON.stringify(calculatedOrderPrice)) {
            setCalculatedOrderPrice(price.data)
        }
    }, [calculatedOrderPrice, price.data, setCalculatedOrderPrice])

    const currentStepBySearchParam: CheckoutStep | undefined = useMemo(() => {
        const stepParam = searchParams.get("s")
        const s = Object.keys(checkoutSteps).find((k) => {
            const stepProps = section[k as CheckoutStep]
            return localize(stepProps.slug) === stepParam
        })
        return s as CheckoutStep | undefined
    }, [localize, searchParams, section])

    // Sets the step search param "s" to the slug defined for the provided CheckoutStep.
    const setSearchParamStep = useCallback(
        (s: CheckoutStep, options?: NavigateOptions) => {
            const sp = Object.fromEntries(searchParams)
            delete sp.auth
            setSearchParams(
                {
                    ...sp,
                    s: localize(section[s].slug),
                },
                options
            )
        },
        [localize, searchParams, section, setSearchParams]
    )

    // If changing step to a different step than Payment, delete the order to be able
    // to modify it and get updated prices from usePhoneOrderPrice.
    useEffect(() => {
        if (currentStep !== "Payment" && order?.id) {
            void deleteOrder()
        }
    }, [currentStep, deleteOrder, order?.id])

    const postAnalyticsCheckoutEvent = useCallback(
        async (entity: string[], data?: Record<string, string | undefined>) => {
            void postAnalyticsEvent(
                "checkout",
                {
                    entity,
                    data,
                },
                // Override default Fuse Analytics session id with the current checkout id,
                // to get a unique Fuse Analytics session per checkout and not mixed with other
                // checkouts from same user during the same "user session".
                { "Fuse-Analytics-Session": checkoutState.id?.valueOf() ?? "" }
            )
        },
        [checkoutState.id]
    )

    // It appears that it is a good idea to both set the step and update the search param, rather
    // than setting search param and relying on the effect to set current step by search param,
    // so use this function to set step.
    const setStep = useCallback(
        (s: CheckoutStep, options?: NavigateOptions) => {
            setCurrentStep(s)
            setSearchParamStep(s, options)
            if (!checkoutState.availableSteps.includes(s)) {
                setCheckoutState({
                    ...checkoutState,
                    availableSteps: [...checkoutState.availableSteps, s],
                })
            }

            // Ensure page is scrolled to top after changing step.
            if (typeof window !== "undefined") {
                window.scrollTo({ top: 0, behavior: "instant" })
            }

            void postAnalyticsCheckoutEvent(["step", s.toLowerCase(), "enter"], {
                userId: me.data?.id?.valueOf(),
                phoneId: offer?.id.valueOf(),
                rentalPeriod: offer?.rentalPeriod.toString(),
            })
        },
        [
            checkoutState,
            me.data?.id,
            offer?.id,
            offer?.rentalPeriod,
            postAnalyticsCheckoutEvent,
            setCheckoutState,
            setCurrentStep,
            setSearchParamStep,
        ]
    )

    // When first entering the checkout flow, the search param "s" might not be set. This effect
    // sets the param as a replacement, to ensure back button takes the user back to the previous
    // page and not checkout without the "s" search param.
    useEffect(() => {
        const auth = searchParams.get("auth")
        if (auth === "success") {
            void postAnalyticsCheckoutEvent(["step", "login", "success"])
            setStep("Shipping")
        } else if (auth === "failed") {
            void postAnalyticsCheckoutEvent(["step", "login", "failed"])
            setStep("Login")
        } else if (!currentStepBySearchParam && !webPageContext.isNavigating) {
            setStep("TradeIn", { replace: true })
        }
    }, [
        checkoutState.id,
        currentStepBySearchParam,
        postAnalyticsCheckoutEvent,
        searchParams,
        setStep,
        webPageContext.isNavigating,
    ])

    // Effect to keep current step up to date with the step defined by the step search param "s".
    useEffect(() => {
        if (currentStepBySearchParam && currentStepBySearchParam !== currentStep) {
            setCurrentStep(currentStepBySearchParam)
        }
    }, [currentStepBySearchParam, setCurrentStep, currentStep])

    useEffect(() => {
        if (skipAuthStep === undefined) {
            async function setSkipAuthIfLoggedIn() {
                const checkMe = await getMe()
                setSkipAuthStep(checkMe.canSkipLoginInCheckout)
            }

            void setSkipAuthIfLoggedIn()
        }
    }, [skipAuthStep, setSkipAuthStep])

    const insuranceOptions = useInsuranceOptions(
        offer?.model ?? null,
        offer?.storage ?? null,
        offer?.color ?? null,
        offer?.rentalPeriod ?? null
    )
    const purchasedInsurance = useMemo(
        () =>
            offer?.insurance
                ? insuranceOptions.data?.find((io) => io.id === offer.insurance)
                : null,
        [insuranceOptions.data, offer?.insurance]
    )

    const deviceCheckoutCardInfoItems = useMemo(() => {
        const infoItems = []
        if (offer) {
            const ii: DeviceCheckoutCardInfoItem = {
                id: "device",
                icon: "device",
                title: localize(offer.name),
                text: [
                    localize(
                        phoneOfferOptions?.colors.find((c) => c.name === offer.color)
                            ?.displayName ?? {}
                    ),
                    localize(
                        phoneOfferOptions?.storageSizes.find((ss) => ss.name === offer.storage)
                            ?.displayName ?? {}
                    ),
                    `${offer.rentalPeriod} ${localize({ no: "måneder", en: "months" })}`,
                ].join(" · "),
            }
            infoItems.push(ii)
        }

        if (checkoutTradeInPriceRequest && checkoutTradeInResult) {
            const ii: DeviceCheckoutCardInfoItem = {
                id: "trade-in",
                icon: "handCoins",
                title: MustacheString(localize(section.CheckoutDeviceCard.tradeInInfoItem.title), {
                    cashback: formatAmount(checkoutTradeInResult.value),
                }),
                text: MustacheString(localize(section.CheckoutDeviceCard.tradeInInfoItem.text), {
                    monthlyDiscount: formatAmount(checkoutTradeInResult.monthlyDiscount),
                    discountPeriod: `${checkoutTradeInResult.months.valueOf()}`,
                }),
            }
            infoItems.push(ii)
        }
        if (offer?.insurance && calculatedOrderPrice?.monthlyCost.insurance && purchasedInsurance) {
            const ii: DeviceCheckoutCardInfoItem = {
                id: "protection-plan",
                icon: "shield",
                title: localize(section.CheckoutDeviceCard.insuranceInfoItem.title),
                text: MustacheString(localize(section.CheckoutDeviceCard.insuranceInfoItem.text), {
                    insuranceName: localize(purchasedInsurance.displayName),
                    amount: `${formatAmount(calculatedOrderPrice.monthlyCost.insurance)}/${localize(monthsShort)}`,
                }),
            }

            infoItems.push(ii)
        }
        if (checkoutShippingState.selectedOption && checkoutShippingState.form) {
            const ii: DeviceCheckoutCardInfoItem = {
                id: "shipping",
                icon: "truck",
                title: localize(checkoutShippingState.selectedOption.name),
                text: MustacheString(
                    !checkoutShippingState.selectedOption.cost.valueOf() &&
                        section.CheckoutDeviceCard.shippingInfoItem.freeShippingText
                        ? localize(section.CheckoutDeviceCard.shippingInfoItem.freeShippingText)
                        : localize(section.CheckoutDeviceCard.shippingInfoItem.text),
                    {
                        shippingPrice: formatAmount({
                            minorUnits: checkoutShippingState.selectedOption.cost * 10,
                            majorUnits: checkoutShippingState.selectedOption.cost,
                            currency: "NOK",
                        }),
                        shippingName: localize(checkoutShippingState.selectedOption.name),
                        shippingDescription: localize(
                            checkoutShippingState.selectedOption.description
                        ),
                    }
                ),
            }
            infoItems.push(ii)
        }

        return infoItems
    }, [
        calculatedOrderPrice?.monthlyCost.insurance,
        checkoutShippingState,
        checkoutTradeInPriceRequest,
        checkoutTradeInResult,
        formatAmount,
        localize,
        offer,
        phoneOfferOptions?.colors,
        phoneOfferOptions?.storageSizes,
        purchasedInsurance,
        section.CheckoutDeviceCard.insuranceInfoItem.text,
        section.CheckoutDeviceCard.insuranceInfoItem.title,
        section.CheckoutDeviceCard.shippingInfoItem.freeShippingText,
        section.CheckoutDeviceCard.shippingInfoItem.text,
        section.CheckoutDeviceCard.tradeInInfoItem.text,
        section.CheckoutDeviceCard.tradeInInfoItem.title,
    ])
    const checkoutNavigationInclStepName = useMemo(
        () =>
            checkoutNavigation.map((cn) => ({
                ...cn,
                name: localize(section[cn.id].name),
                isAvailable: checkoutState.availableSteps.includes(cn.id),
            })),
        [checkoutState.availableSteps, localize, section]
    )
    useEffect(() => {
        if (tradeInForm.data && !checkoutTradeInForm) {
            setCheckoutTradeInForm(tradeInForm.data)
        }
    }, [checkoutTradeInForm, setCheckoutTradeInForm, tradeInForm.data])

    if (offerShouldBeLoaded && !offer) {
        return <ErrorPage />
    }

    if (!offer || skipAuthStep === undefined || me.loading) return <></>

    // If current step is not up to date with search param step, wait with rendering until they
    // are aligned to prevent flash of incorrect step.
    if (currentStepBySearchParam !== currentStep) return <></>

    const StepContent: any = checkoutSteps[currentStep]

    return (
        <CheckoutContext.Provider
            value={{
                props: {
                    TradeIn: section.TradeIn,
                    Insurance: section.Insurance,
                    Login: section.Login,
                    Shipping: section.Shipping,
                    Payment: section.Payment,
                    SoldOut: section.SoldOut,
                },
                insuranceOptions,
                skipAuthStep,
                offer,
                setOffer,
                shipping,
                setShipping,
                price: calculatedOrderPrice,
                step: currentStep,
                setStep,
                checkoutState,
                setCheckoutState,
                postAnalyticsCheckoutEvent,
                useOrder: () => ({
                    order,
                    ensureOrder,
                    deleteOrder,
                    isLoading,
                    errorMessage,
                }),
            }}
        >
            <Global styles={{ body: { backgroundColor: colors.gray100 } }} />
            <div
                css={css(
                    {
                        display: "grid",
                        gridTemplateAreas: '"logo" "progress" "content" "device"',
                        gap: 16,
                        paddingTop: 16,
                        marginBottom: 16,
                    },
                    responsiveCss("min", "md", {
                        gridTemplateAreas: '"progress logo" "content device"',
                        gridTemplateColumns: "1fr 0.68fr",
                        gap: 24,
                        paddingTop: 24,
                        marginBottom: 24,
                    })
                )}
            >
                <Flex gap={8} style={{ gridArea: "logo", minWidth: 0 }} justifyContent="flex-end">
                    {!section.Trustpilot.hide ? (
                        <Flex
                            css={css({ height: scaleValue(48), borderRadius: 100 })}
                            alignItems="center"
                            padding={{ left: scaleValue(24), right: scaleValue(16) }}
                            gap={8}
                            backgroundColor="grayWhite"
                        >
                            {typeof section.Trustpilot.rating !== "undefined" ? (
                                <Text variant="heading" level="4">
                                    {section.Trustpilot.rating}
                                </Text>
                            ) : null}
                            <TrustpilotStar />
                        </Flex>
                    ) : null}
                    <LogoR style={{ height: scaleValue(48) }} />
                </Flex>
                <div style={{ gridArea: "progress", minWidth: 0 }}>
                    <StepsNavigation
                        steps={
                            skipAuthStep
                                ? checkoutNavigationInclStepName.filter((s) => s.id !== "Login")
                                : checkoutNavigationInclStepName
                        }
                        currentStep={currentStep}
                    />
                </div>
                <div style={{ gridArea: "device", minWidth: 0 }}>
                    {offer.images && offer.images[0] ? (
                        <div
                            style={{
                                position: "sticky",
                                top: 24,
                            }}
                        >
                            <DeviceCheckoutCard
                                subscription={{ duration: offer.rentalPeriod, unit: "months" }}
                                checkout={{
                                    rentalPeriodCurrencyPeriodUnit: calculatedOrderPrice
                                        ?.monthlyCost.total.currency
                                        ? `${localizeCurrency(calculatedOrderPrice.monthlyCost.total.currency)}/${localize(monthsShort)}`
                                        : "",
                                    originalPrice:
                                        (calculatedOrderPrice?.monthlyCost.total.majorUnits ?? 0) +
                                        (calculatedOrderPrice?.monthlyCost.tradeInDiscount
                                            ?.majorUnits ?? 0),
                                    discountedPrice:
                                        calculatedOrderPrice?.monthlyCost.total.majorUnits ?? 0,
                                    monthlyTotalPriceLabel: localize(
                                        section.CheckoutDeviceCard.monthlyTotalPriceLabel
                                    ),
                                    rentalPeriodTotalPriceText: MustacheString(
                                        localize(
                                            section.CheckoutDeviceCard.rentalPeriodTotalPriceText
                                        ),
                                        {
                                            rentalPeriod: `${offer.rentalPeriod.valueOf()} ${localize(monthsShort)}`,
                                            totalPrice: calculatedOrderPrice
                                                ? `${formatAmount({ minorUnits: calculatedOrderPrice.monthlyCost.total.minorUnits * offer.rentalPeriod.valueOf(), majorUnits: calculatedOrderPrice.monthlyCost.total.majorUnits * offer.rentalPeriod.valueOf(), currency: calculatedOrderPrice.monthlyCost.total.currency })}`
                                                : "",
                                        }
                                    ),
                                }}
                                infoItems={deviceCheckoutCardInfoItems}
                                product={{
                                    image: offer.images[0] as any,
                                    color: localize(
                                        phoneOfferOptions?.colors.find(
                                            (c) => c.name === offer.color
                                        )?.displayName ?? {}
                                    ),
                                    storageSize: localize(
                                        phoneOfferOptions?.storageSizes.find(
                                            (ss) => ss.name === offer.storage
                                        )?.displayName ?? {}
                                    ),
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                <Flex
                    direction="column"
                    borderRadius="lg"
                    backgroundColor="grayWhite"
                    css={css(
                        {
                            "--padding-x": "16px",
                            padding: "20px var(--padding-x)",
                            gridArea: "content",
                            minWidth: 0,
                            borderRadius: 16,
                        },
                        responsiveCss("min", "md", {
                            "--padding-x": "80px",
                            padding: scaleValue(80),
                        })
                    )}
                >
                    {me.data?.orderLimitReached ? (
                        <OrderLimitReached {...section.OrderLimitReached} />
                    ) : price.error ? (
                        // Sure enough, there could be other reasons for price error, but let's
                        // assume it's sold out for now.
                        <SoldOut {...section.SoldOut} />
                    ) : (
                        <StepContent {...section[currentStep]} />
                    )}
                </Flex>
            </div>
        </CheckoutContext.Provider>
    )
}
Section(Checkout)
